// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-post-js": () => import("./../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-frames-dog-breakdown-js": () => import("./../src/pages/frames/dog/breakdown.js" /* webpackChunkName: "component---src-pages-frames-dog-breakdown-js" */),
  "component---src-pages-frames-dog-food-breakdown-js": () => import("./../src/pages/frames/dog/food-breakdown.js" /* webpackChunkName: "component---src-pages-frames-dog-food-breakdown-js" */),
  "component---src-pages-frames-seamless-monthly-breakdown-js": () => import("./../src/pages/frames/seamless/monthly-breakdown.js" /* webpackChunkName: "component---src-pages-frames-seamless-monthly-breakdown-js" */),
  "component---src-pages-frames-seamless-monthly-costs-js": () => import("./../src/pages/frames/seamless/monthly-costs.js" /* webpackChunkName: "component---src-pages-frames-seamless-monthly-costs-js" */),
  "component---src-pages-frames-seamless-monthly-orders-js": () => import("./../src/pages/frames/seamless/monthly-orders.js" /* webpackChunkName: "component---src-pages-frames-seamless-monthly-orders-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

